import React, {useState} from 'react'
import './StudentInfo.css'

import stuInfoCn from '../images/StudentInfoSpring2025-cn.pdf'
import stuInfoEn from '../images/StudentInfoSpring2025-en.pdf'

function StudentInfo() {
    const [inChinese, setChinese] = useState(true);

    return (
        <div className='student-info'> 
            <span className='student-info__text-translate_panel'>
                {inChinese ? 
                <>
                    <button 
                        className = "cn-font-text student-info__text-translate--selected" onClick = {() => setChinese(true)}>
                            中文</button>
                    <button className = 'cn-font-text' onClick = {() => setChinese(false)}>English</button>
                </>
                :
                <>
                <button 
                className = "cn-font-text" onClick = {() => setChinese(true)}>
                    中文</button>
                <button className = 'cn-font-text student-info__text-translate--selected' onClick = {() => setChinese(false)}>English</button>
                </>
                }
            </span>     
            
            <embed src={inChinese ? stuInfoCn : stuInfoEn} type="application/pdf"></embed>
        </div>
    )
}

export default StudentInfo
